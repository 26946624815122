<template>
  <div style="display:flex; flex-direction: column">
    <div style="margin: 0 auto">
      <visual-byte-logo />
      <visual-byte-menu mode="horizontal" @item-clicked="clicked" />
      <div v-if="this.selectedIndex === 1">
          <information-card title="Software Engineering" image-url="/images/software_engineering.jpg" background-color="#252227">
              <ul style="float:left;">
                  <li>Test 1</li>
                  <li>Test 2</li>
              </ul>
          </information-card>
        <information-card title="Cloud Native" image-url="/images/cloud_native.png" background-color="#152542">
            Cloud native development...
        </information-card>
        <information-card title="DevOps, CI/CD & Microservices" image-url="/images/devops.png" background-color="#181a1a" />
        <information-card title="Optimization" image-url="/images/optimization.png" />
      </div>
    </div>
  </div>
</template>

<script>
import InformationCard from "@/components/InformationCard";
import VisualByteMenu from "@/components/VisualByteMenu";
import VisualByteLogo from "@/components/VisualByteLogo";

export default {
  name: 'App',
  components: {
    VisualByteMenu,
    InformationCard,
    VisualByteLogo
  },
  methods: {
    clicked: function(e) {
        if ([1,2].find(i => i === Number(e))) {
            this.selectedIndex = Number(e);
        }
    }
  },
  data() {
    return {
      selectedIndex: 1
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
