<template>
  <div>
    <a-menu :mode="mode" @click="({key}) => $emit('itemClicked', key)" theme="light">
      <a-menu-item key="1">
        <template #icon>
          <PieChartOutlined />
        </template>
        <span>Services</span>
      </a-menu-item>
      <a-menu-item key="3">
        <template #icon>
          <InboxOutlined />
        </template>
        <span>
          <a href="mailto:daan@visualbyte.nl" target="_blank" rel="noopener noreferrer">
          Contact
        </a>
        </span>
      </a-menu-item>
      <a-menu-item key="4">
        <template #icon>
          <LinkedinFilled />
        </template>
        <span>
          <a href="https://www.linkedin.com/in/daan-van-manen/" target="_blank">
          LinkedIn
          </a>
        </span>
      </a-menu-item>
    </a-menu>
  </div>
</template>
<script>
import {PieChartOutlined, InboxOutlined, LinkedinFilled} from "@ant-design/icons-vue";
export default {
  components: {
    PieChartOutlined,
    InboxOutlined,
    LinkedinFilled
  },
  name: 'VisualByteMenu',
  props: {
    mode: String
  }
}
</script>