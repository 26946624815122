<template>
  <div class="information-card">
    <a-card hoverable>
      <template #cover>
        <div :style="`background-color: ${backgroundColor}`">
          <img alt="example" :src="imageUrl" />
        </div>
      </template>
      <a-card-meta :title="title">
        <template #description><slot></slot></template>
      </a-card-meta>
    </a-card>
  </div>
</template>
<script>
export default {
  name: 'InformationCard',
  props: {
    imageUrl: String,
    title: String,
    backgroundColor: String
  }
}
</script>
<style>
  div.information-card {
    margin: 1em;
  }
  div.ant-card-cover {
    height:200px;
    overflow:hidden;
  }

  div.ant-card-cover img {
    margin: 0 auto;
    height:200px;
    width:auto;
  }
</style>