<template>
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
       width="286.720000pt" height="176.400000pt" viewBox="0 0 4096.000000 2528.000000"
       preserveAspectRatio="xMidYMid meet">
    <metadata>
      Created by potrace 1.16, written by Peter Selinger 2001-2019
    </metadata>
    <g transform="translate(0.000000,2528.000000) scale(0.100000,-0.100000)"
       :fill="`hsl(${x}, 80%, 30%)`" stroke="none">
      <path d="M20240 19954 c-486 -33 -754 -73 -1140 -170 -1023 -258 -1982 -832
-2694 -1613 -863 -945 -1358 -2098 -1447 -3366 -17 -249 -7 -760 20 -990 103
-864 361 -1603 815 -2330 216 -346 442 -631 756 -956 221 -228 583 -523 890
-725 782 -514 1661 -814 2600 -890 278 -22 807 -15 1055 15 1105 132 2091 553
2922 1247 337 282 615 573 876 919 538 712 888 1522 1037 2400 271 1594 -163
3219 -1189 4460 -99 119 -323 364 -383 419 -24 22 -77 71 -118 110 -340 323
-840 671 -1285 893 -607 304 -1339 510 -2000 563 -136 11 -620 20 -715 14z
m625 -335 c972 -71 1913 -419 2701 -999 454 -335 894 -790 1216 -1260 736
-1074 1040 -2392 852 -3695 -120 -833 -469 -1675 -971 -2345 -510 -680 -1163
-1220 -1918 -1586 -1045 -506 -2213 -644 -3374 -399 -273 58 -652 178 -921
292 -906 384 -1682 1015 -2253 1832 -139 197 -253 390 -372 626 -314 623 -489
1252 -544 1954 -6 74 -11 252 -11 395 0 359 29 669 91 986 170 869 551 1668
1123 2352 290 347 676 698 1046 950 995 679 2132 985 3335 897z"/>
      <path d="M20160 19430 c-980 -65 -1919 -409 -2680 -984 -198 -149 -335 -270
-541 -475 -406 -405 -686 -791 -939 -1291 -274 -544 -438 -1103 -507 -1730
-22 -205 -25 -813 -5 -1010 117 -1132 562 -2116 1330 -2947 106 -114 327 -320
487 -453 596 -496 1380 -872 2140 -1025 343 -69 571 -94 935 -102 477 -11 904
38 1371 156 1242 315 2347 1141 3028 2261 105 173 295 554 364 730 507 1292
467 2730 -112 3976 -484 1044 -1345 1916 -2378 2410 -538 258 -1082 409 -1683
469 -189 19 -626 27 -810 15z m-1644 -2191 c94 -13 213 -54 289 -100 192 -115
333 -332 360 -552 l7 -57 653 0 c360 0 660 -3 669 -6 14 -6 16 -106 16 -1035
l0 -1029 645 0 645 0 0 23 c0 40 29 161 52 217 89 221 274 387 505 452 102 28
286 31 383 4 262 -70 473 -283 541 -545 28 -106 28 -268 0 -374 -38 -145 -133
-295 -247 -389 -377 -311 -937 -191 -1157 247 -32 65 -63 166 -71 238 l-7 57
-645 0 -644 0 -2 -1032 -3 -1033 -666 -3 -667 -2 -7 -57 c-28 -229 -173 -444
-377 -560 -198 -113 -452 -129 -663 -40 -80 33 -185 105 -246 168 -282 292
-289 742 -16 1035 149 160 339 244 552 244 156 0 283 -38 407 -119 184 -121
307 -310 339 -518 l12 -83 639 0 638 0 0 2035 0 2035 -638 0 -638 0 -12 -75
c-27 -171 -91 -295 -221 -426 -140 -140 -287 -206 -480 -216 -147 -8 -245 12
-375 76 -301 147 -469 472 -413 796 58 330 311 577 640 625 78 11 118 11 203
-1z"/>
      <path d="M22395 15095 c-224 -50 -427 -236 -496 -455 -58 -182 -33 -405 63
-568 53 -90 180 -211 268 -255 411 -205 880 9 991 453 18 69 21 103 16 185
-16 311 -218 560 -515 636 -88 22 -235 24 -327 4z"/>
      <path d="M15259 7625 c-280 -53 -476 -215 -532 -441 -24 -94 -29 -172 -17
-267 14 -111 39 -179 101 -271 90 -135 185 -204 493 -355 214 -106 304 -167
344 -236 33 -58 28 -164 -10 -222 -29 -44 -101 -85 -172 -97 -66 -12 -232 -7
-313 10 -99 20 -278 81 -435 148 l-38 16 0 -228 0 -228 83 -31 c160 -62 298
-90 496 -99 205 -10 407 24 540 90 235 119 351 305 351 566 0 234 -94 401
-302 540 -50 34 -192 112 -317 175 -124 62 -245 130 -268 149 -60 52 -86 112
-81 186 7 96 64 163 163 192 52 15 192 12 286 -6 71 -13 243 -67 319 -99 l35
-15 72 170 c94 220 90 204 56 222 -48 24 -233 86 -338 112 -127 33 -394 42
-516 19z"/>
      <path d="M19156 6852 c-314 -895 -409 -1165 -477 -1356 -27 -77 -49 -141 -49
-143 0 -2 118 -2 262 -1 l262 3 81 265 82 265 406 3 406 2 21 -72 c12 -40 48
-160 81 -265 32 -106 59 -195 59 -198 0 -3 117 -5 260 -5 182 0 260 3 260 11
0 6 -133 387 -296 847 -162 461 -341 966 -396 1125 l-101 287 -296 0 -296 0
-269 -768z m724 -132 c65 -212 122 -393 125 -402 7 -17 -11 -18 -279 -18 -224
0 -284 3 -281 13 28 86 235 773 261 866 l17 64 19 -69 c11 -38 73 -242 138
-454z"/>
      <path d="M11262 7598 c3 -7 175 -516 383 -1131 l379 -1117 265 2 264 3 382
1125 383 1125 -241 3 c-132 1 -244 -1 -248 -5 -12 -13 -434 -1346 -472 -1493
-20 -74 -43 -171 -53 -214 l-17 -80 -33 145 c-48 206 -70 280 -298 999 l-204
645 -247 3 c-196 2 -246 0 -243 -10z"/>
      <path d="M13412 7468 l3 -142 133 -60 132 -60 0 -726 0 -726 -135 -59 -135
-60 0 -142 0 -143 515 0 516 0 -3 142 -3 143 -132 59 -133 60 0 725 0 726 133
61 132 61 3 142 3 141 -516 0 -516 0 3 -142z"/>
      <path d="M16533 6818 c3 -514 8 -812 15 -847 54 -264 191 -445 420 -553 185
-88 424 -117 672 -83 143 19 237 48 345 103 72 37 108 64 180 137 102 101 156
195 197 340 22 79 22 86 26 888 l3 807 -243 -2 -243 -3 -5 -775 -5 -775 -23
-64 c-43 -119 -110 -193 -209 -232 -78 -30 -286 -34 -377 -5 -103 31 -179 96
-218 186 -45 102 -47 154 -48 933 l0 737 -246 0 -245 0 4 -792z"/>
      <path d="M21090 6480 l0 -1130 698 2 697 3 3 198 2 197 -460 0 -460 0 0 930 0
930 -240 0 -240 0 0 -1130z"/>
      <path d="M22870 6480 l0 -1130 438 0 c480 0 576 8 723 56 275 91 435 334 416
629 -19 276 -150 419 -457 496 l-45 12 70 22 c101 31 167 70 229 135 91 95
126 191 126 345 -1 344 -195 514 -637 554 -67 6 -288 11 -492 11 l-371 0 0
-1130z m931 853 c194 -50 277 -158 267 -347 -3 -60 -11 -95 -30 -136 -45 -96
-100 -138 -224 -171 -69 -19 -117 -22 -366 -26 l-288 -6 0 358 0 357 283 -5
c237 -4 294 -8 358 -24z m38 -952 c92 -27 141 -52 192 -98 83 -75 122 -205
101 -339 -27 -175 -113 -266 -296 -317 -61 -17 -113 -20 -373 -24 l-303 -5 0
407 0 408 303 -6 c269 -4 310 -7 376 -26z"/>
      <path d="M24600 7601 c0 -4 167 -317 370 -694 l370 -686 0 -436 0 -435 150 0
150 0 0 441 0 441 370 679 c204 374 370 684 370 689 0 6 -62 10 -153 10 l-154
0 -289 -545 c-158 -300 -291 -545 -294 -545 -4 0 -131 240 -459 865 -40 77
-84 159 -97 183 l-25 42 -154 0 c-85 0 -155 -4 -155 -9z"/>
      <path d="M26410 7480 l0 -130 350 0 350 0 0 -1000 0 -1000 145 0 145 0 0 1000
0 1000 350 0 350 0 0 130 0 130 -845 0 -845 0 0 -130z"/>
      <path d="M28430 6480 l0 -1130 635 0 635 0 0 130 0 130 -485 0 -485 0 0 395 0
395 455 0 455 0 0 130 0 130 -455 0 -455 0 0 345 0 345 485 0 485 0 0 130 0
130 -635 0 -635 0 0 -1130z"/>
    </g>
  </svg>
</template>
<script>
  export default {
    name: 'VisualByteLogo',
    data() {
      return {
        x: 0,
        compute: x => x+1
      }
    },
    mounted() {
      setInterval(() => {
        if (this.x === 1000) {
          this.compute = x => x - 1;
        } else if (this.x === 0) {
          this.compute = x => x + 1;
        }
        this.x = this.compute(this.x);
      }, 300);
    }
}
</script>